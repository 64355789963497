<template>
  <div>
    <md-tabs :md-active-tab="current_tab" @md-changed="listenerChangeTab">
      <md-tab id="tab-detalle" md-label="Detalles" >
        <md-table >
          <md-table-toolbar>
            <h1 class="md-title">Lista Partidas
            <p class="md-caption">Total items: {{list_data.mdData.length}}</p>
            </h1>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to('/')">
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
            </md-menu>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to('/partidas/nuevo')">
                <md-icon>add</md-icon>
              </md-button>
            </md-menu>
            <md-menu md-size="auto">
              <md-button class="md-icon-button" @click.prevent="handler_open_filter()">
                <md-icon>filter_list</md-icon>
              </md-button>
            </md-menu>
            <md-menu md-size="auto">
              <md-button title="Exportar a excel" class="md-icon-button"
              @click.prevent="printer(null, 'excel', 'libro_diario')">
                <md-icon v-if="!sending_request">grid_on</md-icon>
                <md-progress-spinner v-if="sending_request" class="md-primary"
                :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
              </md-button>
            </md-menu>
            <md-menu md-size="auto">
              <md-button title="Libro diario a PDF"  @click.prevent="
              printer(null, 'pdf', 'libro_diario')">
                <md-icon v-if="!sending_request">printer</md-icon>
                <md-progress-spinner v-if="sending_request" class="md-primary"
                :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
              </md-button>
            </md-menu>
          </md-table-toolbar>
          <md-table-row>
            <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
            <md-table-head>Fecha documento</md-table-head>
            <md-table-head ># Partida</md-table-head>
            <md-table-head >Usuario</md-table-head>
            <md-table-head >Tipo</md-table-head>
            <md-table-head :md-numeric="aligns_numeric">Total debe</md-table-head>
            <md-table-head :md-numeric="aligns_numeric">Total haber</md-table-head>
            <md-table-head class="md-xsmall-hide md-small-hide" :md-numeric="aligns_numeric">
            Diferencia</md-table-head>
            <md-table-head >Editar</md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, index) in list_data.mdData" :key="item.id"
          :class="{ 'null error-text': !item.status}">
              <md-table-cell class="md-xsmall-hide md-small-hide" >{{index + 1}}</md-table-cell>
              <md-table-cell >{{ item.date_partida|to_date }}
              </md-table-cell>
              <md-table-cell>{{ item.numero_partida }}</md-table-cell>
              <md-table-cell>
                <span v-if="item.name_creator">{{ item.name_creator }}</span>
                <span v-if="!item.name_creator">-----</span>
              </md-table-cell>
              <md-table-cell>{{ item.tipo_partida }}</md-table-cell>
              <md-table-cell :md-numeric="aligns_numeric">
                {{ item.total_debe|formatPrice }}
              </md-table-cell>
              <md-table-cell :md-numeric="aligns_numeric">
                {{ item.total_haber|formatPrice }}
              </md-table-cell>
              <md-table-cell :md-numeric="aligns_numeric"
              class="md-xsmall-hide md-small-hide">
                  <md-badge v-if="item.total_debe != item.total_haber"
                  class="md-square" :md-content="item.total_debe - item.total_haber|formatPrice" />
                  <span v-if="item.total_debe === item.total_haber">
                  {{ (item.total_debe - item.total_haber)|formatPrice }}</span>
                </md-table-cell>
              <md-table-cell >
                <md-button class="md-icon-button" @click="go_to(`/partidas/${item.id}`)">
                  <md-icon class="md-size-1x" >edit</md-icon>
                </md-button>
                <a title="Ir a documento" target="_blank"
                v-if="item.tipo_partida == 'CXC' && item.documento" class="md-icon-button"
                :href="'/form_detalle_ventas/'+item.documento">
                  <md-icon class="md-size-1x" >launch</md-icon>
                </a>
                <a title="Ir a documento"
                :href="'/compras/'+item.documento" target="_blank"
                v-if="item.tipo_partida == 'CXP' && item.documento" class="md-icon-button">
                  <md-icon class="md-size-1x" >launch</md-icon>
                </a>
                <a title="Ir a documento"
                :href="'/form_movimientos_bancarios/'+item.documento" target="_blank"
                v-if="item.tipo_partida == 'BAN' && item.documento" class="md-icon-button">
                  <md-icon class="md-size-1x" >launch</md-icon>
                </a>
                <a title="Ver documento de gasto"
                @click.prevent="handler_open_modal_doc_gasto(item.documento_gasto)"
                v-if="item.documento_gasto" href="#"
                class="md-icon-button"><md-icon class="md-size-1x" >launch</md-icon>
                </a>
                <a title="Anular"
                @click.prevent="delete_partida(item, index)"
                v-if="item.status" href="#"
                class="md-icon-button"><md-icon class="md-size-1x" >delete</md-icon>
                </a>
              </md-table-cell>
          </md-table-row>
        </md-table>
        <md-empty-state v-if="list_data.mdData.length === 0"
          md-icon="free_breakfast"
          md-label="Crear partida"
          md-description="Aún no hay partidas registradas, crea la primera.">
          <md-button md-theme="blue-button" class="md-accent md-raised"
            @click="go_to(`/partidas/nuevo/`)">
            Crear partida</md-button>
        </md-empty-state>
      </md-tab>
      <md-tab id="tab-libros" md-label="Libros">
        <md-table v-model="list_data_book.mdData"
        md-sort="name" md-sort-order="asc" md-card md-fixed-header>
          <md-table-toolbar>
            <h1 class="md-title">Libro Diario</h1>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to('/')">
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
              <md-menu md-size="big" md-direction="bottom-end">
                <md-button class="md-icon-button" @click="go_to(`/libro_diario`)">
                  <md-icon>add</md-icon>
                </md-button>
              </md-menu>
              <md-menu md-size="auto">
                <md-button @click.prevent="showDialog_book=true">
                  <md-icon>filter_list</md-icon>
                </md-button>
              </md-menu>
            </md-menu>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Autorizacion"
            md-sort-by="authorization">{{item.authorization }}</md-table-cell>
            <md-table-cell md-label="Ejercicio fiscal" md-sort-by="year">
            {{ item.year }}</md-table-cell>
            <md-table-cell md-label="Fecha autorización"
            md-sort-by="date_authorization">{{ item.date_authorization|to_date }}</md-table-cell>
            <md-table-cell md-label="Activo"
              md-sort-by="status">{{ item.status|status }}</md-table-cell>
            <md-table-cell md-label="Seleccinar"  title="Click para elegir este libro">
              <md-button class="md-icon-button md-primary" @click="set_book(item)">
                <md-icon class="md-size-2x" >done</md-icon>
              </md-button>
            </md-table-cell>
            <md-table-cell md-label="Creado por"
              md-sort-by="creator_name" v-if="item.creator_name">
            {{ item.creator_name }}</md-table-cell>
            <md-table-cell md-label="Creado por"
              md-sort-by="creator_name" v-if="!item.creator_name">-----</md-table-cell>
            <md-table-cell md-label="Editar" >
              <md-button class="md-icon-button" @click="go_to(`/libro_diario/${item.id}`)">
                <md-icon class="md-size-1x" >edit</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
          <md-table-pagination
            :md-page-size="rowsPerPage"
            :md-page-options="[3, 5, 10, 15]"
            :md-update="list"
            :md-paginated-data.sync="list_data_book" />
        </md-table>
        <md-empty-state
          md-icon="free_breakfast" v-if="list_data_book.mdData.length === 0"
          md-label="Crear el libro diario"
          md-description="Aún no hay libros registrados, crea el primero.">
          <md-button
          md-theme="blue-button" class="md-accent
          md-raised" @click="go_to('/libro_diario')">Crear libro diario</md-button>
        </md-empty-state>
      </md-tab>
    </md-tabs>
    <md-progress-bar md-mode="indeterminate" v-if="sending_request" />
    <md-dialog :md-active.sync="showDialog_detalle">
      <md-dialog-title>
        Filtro partidas
        <md-menu md-size="auto">
          <md-button  @click.prevent="printer(null, 'pdf', 'libro_diario')">
            <md-icon v-if="!sending_request">printer</md-icon>
            <md-progress-spinner v-if="sending_request" class="md-primary"
            :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
          </md-button>
        </md-menu>
      </md-dialog-title>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-50 md-size-25 ">
            <md-field>
              <label for="type_partida">Tipo partida</label>
              <md-select v-model="filters_detalle.tipo_partida"
                name="movie"
                id="movie"
              >
              <md-option :value="item.value"
                v-for="(item, key) in choosen_type_partida"
                :key="key">{{item.text}}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-25">
            <md-field class="md-focused">
              <label for="numero_partida">Número partida:</label>
              <md-input
              v-model="filters_detalle.numero_partida" type="number" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-25">
            <md-field >
              <label for="period">Año fiscal</label>
              <md-select v-model="filters_detalle.fiscal_year">
              <md-option value="">----</md-option>
              <md-option :value="item.id"
                v-for="(item, key) in list_years"
                :key="key">{{item.year}}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50" >
            <p></p>
              <md-checkbox v-model="filters_detalle.sin_detalles">
              Sin detalles</md-checkbox>
            <span v-if="filters_detalle.sin_detalles" style="color: #ff5252;
            font-weight:
            500;" for="services">SÍ</span>
            <span v-if="!filters_detalle.sin_detalles" style="color: #ff5252;
            font-weight: 500;" for="services">NO</span>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-50">
            <md-field class="md-focused">
              <label for="phone">Del:</label>
              <md-input
              v-model="filters_detalle.del" type="date" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50">
            <md-field class="md-focused">
              <label for="phone">Al:</label>
              <md-input
              v-model="filters_detalle.al" type="date" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50">
            <md-field class="md-focused">
              <label for="limit">Limite:</label>
              <md-input @change="list()"
              v-model="filters_detalle.limit" type="number" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50" >
            <p></p>
            <div class="md-layout-item md-small-size-100">
              <md-checkbox v-model="filters_detalle.sin_cuadrar">
              SIN CUADRAR</md-checkbox>
            <span v-if="filters_detalle.sin_cuadrar" style="color: #ff5252;
            font-weight:
            500;" for="services">SÍ</span>
            <span v-if="!filters_detalle.sin_cuadrar" style="color: #ff5252;
            font-weight: 500;" for="services">NO</span>
            </div>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-75 md-small-size-100">
            <md-autocomplete autocomplete="off"
            id="account_name"
            :disabled="sending_look_account"
            v-model="filters_detalle.account_name"
            :md-options="list_account"
            @md-selected="set_account"
            @md-changed="get_list_accounts" >
              <label>Cuenta contable</label>
              <template
              slot="md-autocomplete-item" slot-scope="{ item, term }">
                <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
              </template>
            </md-autocomplete>
          </div>
          <div class="md-layout-item md-size-20 md-small-size-100" >
            <p></p>
            <div class="md-layout-item md-small-size-100">
              <md-checkbox v-model="filters_detalle.status">
              Activo</md-checkbox>
            <span v-if="filters_detalle.status" style="color: #ff5252;
            font-weight:
            500;" for="services">SÍ</span>
            <span v-if="!filters_detalle.status" style="color: #ff5252;
            font-weight: 500;" for="services">NO</span>
            </div>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-50">
            <md-button @click.prevent="printer('year', 'pdf', 'libro_diario')"
            tittle="Click pra imprimir las partidas de este año"
            class="md-primary">Este año</md-button>
          </div>
          <div class="md-layout-item md-small-size-50">
            <md-button @click.prevent="printer('month', 'pdf', 'libro_diario')"
            tittle="Click pra imprimir las partidas de este año"
            class="md-primary">Este mes</md-button>
          </div>
          <div class="md-layout-item md-small-size-50">
            <md-button @click.prevent="printer('last_year', 'pdf', 'libro_diario')"
            tittle="Click pra imprimir las partidas de este año"
            class="md-primary">Año anterior</md-button>
          </div>
          <div class="md-layout-item md-small-size-50">
            <md-menu md-direction="bottom-end">
              <md-button md-menu-trigger >LIBRO MAYOR</md-button>
              <md-progress-spinner v-if="sending_request" class="md-primary"
              :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
              <md-menu-content>
                <md-menu-item
                @click.prevent="printer(null, 'pdf', 'libro_mayor')"
                tittle="FORMATO PDF">PDF</md-menu-item>
                <md-menu-item
                @click.prevent="printer(null, 'excel', 'libro_mayor')"
                tittle="FORMATO EXCEL">EXCEL</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
          <div class="md-layout-item md-small-size-50">
            <md-menu md-direction="bottom-end">
              <md-button md-menu-trigger >SALDOS</md-button>
              <md-progress-spinner v-if="sending_request" class="md-primary"
              :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
              <md-menu-content>
                <md-menu-item
                @click.prevent="printer(null, 'pdf', 'libro_saldos')"
                tittle="FORMATO PDF">PDF</md-menu-item>
                <md-menu-item
                @click.prevent="printer(null, 'excel', 'libro_saldos')"
                tittle="FORMATO EXCEL">EXCEL</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
          <div class="md-layout-item md-small-size-50">
            <md-menu md-direction="bottom-end">
              <md-button md-menu-trigger >BALANCE</md-button>
              <md-progress-spinner v-if="sending_request" class="md-primary"
              :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
              <md-menu-content>
                <md-menu-item
                @click.prevent="printer(null, 'pdf', 'libro_balance')"
                tittle="FORMATO PDF">PDF</md-menu-item>
                <md-menu-item
                @click.prevent="printer(null, 'excel', 'libro_balance')"
                tittle="FORMATO EXCEL">EXCEL</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
          <div class="md-layout-item md-small-size-50">
            <md-menu md-direction="bottom-end">
              <md-button md-menu-trigger >ESTADO RESULTADOS</md-button>
              <md-progress-spinner v-if="sending_request" class="md-primary"
              :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
              <md-menu-content>
                <md-menu-item
                @click.prevent="printer(null, 'pdf', 'libro_estado_resultados')"
                tittle="FORMATO PDF">PDF</md-menu-item>
                <md-menu-item
                @click.prevent="printer(null, 'excel', 'libro_estado_resultados')"
                tittle="FORMATO EXCEL">EXCEL</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </div>
        <!--
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-button @click.prevent="fix_partida_ventas()"
            tittle="corregir partida ventas"
            class="md-primary">Corrgir partidas de ventas</md-button>
          </div>
        </div>
      -->
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog_detalle = false">Cerrar</md-button>
        <md-button class="md-primary"
        @click="reset_filters('filters_detalle')">Limpiar filtro</md-button>
        <md-button class="md-primary" @click="list()">Filtrar</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showDialog_book">
      <md-dialog-title>Filtro libros</md-dialog-title>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="name">Número autorizacion</label>
              <md-input @keyup.enter="list_book(1, rowsPerPage)"
              v-model="filters_book.authorization" type="text" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field class="md-focused">
              <label for="date_authorization">Fecha autorización</label>
              <md-input @keyup.enter="list_book(1, rowsPerPage)"
              v-model="filters_book.date_authorization" type="date" :disabled="sending_request" />
            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog_book = false">Cerrar</md-button>
        <md-button class="md-primary"
        @click="reset_filters('filters_book')">Limpiar filtro</md-button>
        <md-button class="md-primary" @click="list_book(1, rowsPerPage)">Filtrar</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showDialog_doc_gasto">
      <md-dialog-title>Documento de gasto #{{gasto_obj.correlative}}</md-dialog-title>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item ">
            <md-field class="md-focused">
              <label for="name">Monto</label>
              <md-input v-model="gasto_obj.amount" type="number" disabled="disabled" />
            </md-field>
          </div>
          <div class="md-layout-item ">
            <md-field class="md-focused">
              <label for="date_authorization">Fecha gasto</label>
              <md-input v-model="gasto_obj.date_expense" type="date" disabled="disabled" />
            </md-field>
          </div>
          <div class="md-layout-item ">
            <md-field class="md-focused">
              <label for="date_authorization">Bienes</label>
              <md-input v-model="gasto_obj.goods" type="number" disabled="disabled" />
            </md-field>
          </div>
          <div class="md-layout-item ">
            <md-field class="md-focused">
              <label for="date_authorization">Servicios</label>
              <md-input v-model="gasto_obj.services" type="number" disabled="disabled" />
            </md-field>
          </div>
        </div>
        <fieldset>
          <details>
            <summary>
              Combustible (<span v-if="gasto_obj.fuel">
                {{gasto_obj.fuel}}</span>
              <span v-if="!gasto_obj.fuel">0.00</span>)
            </summary>
            <div class="md-layout md-gutter">
              <div class="md-layout-item">
                <md-field class="md-focused">
                  <label for="date_authorization">Galones super</label>
                  <md-input v-model="gasto_obj.super_fuel" type="number" disabled="disabled" />
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field class="md-focused">
                  <label for="date_authorization">Galones regular</label>
                  <md-input v-model="gasto_obj.regular_fuel" type="number" disabled="disabled" />
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field class="md-focused">
                  <label for="date_authorization">Galones diesel</label>
                  <md-input v-model="gasto_obj.diesel_fuel" type="number" disabled="disabled" />
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field class="md-focused">
                  <label for="date_authorization">Precio galon super</label>
                  <md-input v-model="gasto_obj.gallon_price_super" type="number"
                  disabled="disabled"/>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field class="md-focused">
                  <label for="date_authorization">Precio galon regular</label>
                  <md-input v-model="gasto_obj.gallon_price_regular" type="number"
                  disabled="disabled"/>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field class="md-focused">
                  <label for="date_authorization">Precio galon diesel</label>
                  <md-input v-model="gasto_obj.gallon_price_diesel" type="number"
                  disabled="disabled"/>
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item">
                <md-field class="md-focused">
                  <label for="name">IDP</label>
                  <md-input v-model="gasto_obj.idp" type="number" disabled="disabled" />
                </md-field>
              </div>
            </div>
          </details>
        </fieldset>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field class="md-focused">
              <label for="name">Base</label>
              <md-input v-model="gasto_obj.base" type="number" disabled="disabled" />
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field class="md-focused">
              <label for="date_authorization">IVA</label>
              <md-input v-model="gasto_obj.iva" type="number" disabled="disabled" />
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field class="md-focused">
              <label for="date_authorization">Serie</label>
              <md-input v-model="gasto_obj.serie" type="text" disabled="disabled" />
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field class="md-focused">
              <label for="date_authorization">Numero</label>
              <md-input v-model="gasto_obj.serie" type="text" disabled="disabled" />
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field class="md-focused">
              <label for="date_authorization">Comentario</label>
              <md-input v-model="gasto_obj.comment" type="text" disabled="disabled" />
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field class="md-focused">
              <label for="date_authorization">Rubro</label>
              <md-input v-model="gasto_obj.account_account_name" type="text"
              disabled="disabled"/>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field class="md-focused">
              <label for="date_authorization">Establecimiento</label>
              <md-input v-model="gasto_obj.store_name" type="text" disabled="disabled" />
            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog_doc_gasto = false">Cerrar</md-button>
      </md-dialog-actions>
    </md-dialog>
    <br>
    <br>
  </div>
</template>
<script>
import moment from 'moment';
import Swal from 'sweetalert2';
/* eslint-disable */
export default {
  name: 'Partida',
  created(){
    let current_filters_book = this.get_data_storage(this.name_storage_filter_book)
    if(current_filters_book){
      this.filters_book = current_filters_book
    }
    let current_filters_detalle = this.get_data_storage(this.name_storage_filter_detalle)
    if(current_filters_detalle){
      this.filters_detalle = current_filters_detalle
    }
    const yearSelected = this.get_data_storage_raw('id_fiscal_year');
    if (yearSelected) {
      this.fiscal_year_id = yearSelected;
    }

    document.addEventListener('keyup', e => {
      if (e.key === 'f4' || e.keyCode === 115) {
        if(this.current_tab === 'tab-detalle'){
          this.handler_open_filter()
        }
        if(this.current_tab === 'tab-libros'){
          this.showDialog_book = true
        }
      }
    })

    this.list()

  },
  data() {
    return {
      showDialog_detalle: false,
      showDialog_book: false,
      showDialog_doc_gasto: false,
      aligns_numeric: true,
      current_tab: 'tab-detalle',
      name_storage_filter_detalle: 'filter_partida_detalle',
      name_storage_filter_book: 'filter_diary_book',
      list_data: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      list_data_book: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      rowsPerPage: 60,
      filters_detalle: {
        limit: 60,
      },
      list_years: [],
      filters_book: {
      },
      instance: {
        id: null
      },
      gasto_obj: {},
      fiscal_year_id: null,
      url: 'diary_book_detail/',
      url_book: 'diary_book/',
      url_fiscal_year: 'fiscal_year',
      url_gastos: 'gastos/',
      url_accounts: 'accounting_account/',
      list_account: [],
      sending_request: false,
      sending_look_account: false,
      choosen_type_partida: [
      {value:'', text: 'Elige un tipo'},
        {value:'DR', text: 'DIARIO'},
        {value:'CXC', text: 'CXC'},
        {value:'CXP', text: 'CXP'},
        {value:'PC', text: 'PC'},
        {value:'BAN', text: 'BANCO'},
      ],
    };
  },
  methods: {
    handler_open_modal_doc_gasto(id_gasto) {
      const payload = {};
      payload.id=id_gasto
      const self = this;
      this.retrieveRequest(this.url_gastos, payload).then((data) => {
        self.gasto_obj = data.data;
        self.showDialog_doc_gasto = true
      }).catch((error) => {
        self.handlerError(error);
      });
    },
    handler_open_filter() {
      this.get_list_years();
      this.showDialog_detalle = true
    },
    listenerChangeTab(tab_id){
      this.current_tab = tab_id

      if (tab_id==='tab-libros' && this.list_data_book.mdData.length === 0){
        this.list_book(1, this.rowsPerPage)
      }
    },
    go_to(url) {
      this.$router.push(url);
    },
    set_account(obj) {  
      this.filters_detalle.account_name = obj.name;
      this.filters_detalle.account = obj.id;
    },
    get_list_accounts(searchTerm) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
        payload.accountSearch = "1"
      }
      const self = this;
      this.sendRequest_with_url(this.url_accounts, payload).then((data) => {
        self.list_account = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    get_data_storage_raw(name) {
      return localStorage.getItem(name);
    },
    set_book (instance) {
      if(!instance.status) {
        this.$swal.fire('Error!', 'No se puede elegir este libro, esta inactivo.', 'error')
        return false
      }
      this.remove_storage('book_diary')
      this.save_storage('book_diary', instance)
      let libro = this.get_data_storage('book_diary')
      if (libro){
        this.$swal.fire('Seleccionado!', 'El libro ha sido Seleccionado.', 'success')  
        return false
      }
      this.$swal.fire('Error!', 'Pago algo y el libro no fue elegido.', 'error')

    },
    reset_filters: function (name_filter) {
      this[name_filter] = { limit : 30 }
      this.remove_storage(name_filter)
      this.list()
    },
    get_list_years() {
      const payload = {};
      const self = this;
      this.sendRequest_with_url(this.url_fiscal_year, payload).then((data) => {
        self.list_years = data.data;
      }).catch((error) => {
        self.handlerError(error);
      });
      return null;
    },
    list_book(page, pageSize, sort, sortOrder) {
      let payload = {};
      const self = this;
      if (this.sending_request) return false
      this.sending_request = true
      payload.company = this.$store.state.company_obj.id
      if (Object.keys(this.filters_book).length > 0){
        Object.assign(payload, this.filters_book)
      }
      this.sendRequest_with_url(this.url_book, payload).then((data) => {
        self.rowsPerPage = 50
        self.list_data_book = {
          mdData: data.data
        }
        // self.list_data = data.data;
        this.sending_request = false
        this.showDialog_book = false
         this.save_storage(this.name_storage_filter_book, this.filters_book)
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    filter_partida(page, pageSize, sort, sortOrder) {
      let payload = {};
      const self = this;
      payload.paginate = "True"
      payload.page = page
      payload.per_page = pageSize
      if (this.sending_request) return false
      this.sending_request = true
      payload.company = this.$store.state.company_obj.id
      if (Object.keys(this.filters_detalle).length > 0){
        Object.assign(payload, this.filters_detalle)
      }
      this.sendRequest_with_url(this.url, payload).then((data) => {
        self.rowsPerPage = 50
        self.list_data = {
          mdCount: data.data.count,
          mdPage: page,
          mdData: data.data.results
        }
        // self.list_data = data.data;
        this.sending_request = false
        this.save_storage(this.name_storage_filter_detalle, this.filters_detalle,)
        this.showDialog_detalle = false
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    printer(rango=null, format='pdf', libro=null) {
      /*
        rango:
          year : el presente año
          mount: el presente mes
          last_year: el año pasado
          today: hoy
          yesterday: ayer

      */
      let payload = {
        status: 'True',
      };
      payload.printer = "True"
      if (format==='excel') {
        payload.printer = "excel"
      }
      if (libro) {
        payload.libro = libro
      }
      payload.company = this.$store.state.company_obj.id

      payload.rango  = rango

      if (rango === 'year'){
        payload.fecha = moment().format('YYYY-MM-DD');
      }
      if (rango === 'month'){
        payload.fecha = moment().startOf('month').format('YYYY-MM-DD');
      }
      if (rango === 'last_year'){
        payload.fecha = moment().subtract(1, 'years').date(1).format('YYYY-MM-DD');
      }
      if (rango === 'last_month'){
        payload.fecha = moment().subtract(1, 'months').date(1).format('YYYY-MM-DD');
      }
      if(this.filters_detalle.tipo_partida) {
        payload.tipo_partida = this.filters_detalle.tipo_partida;
      }
      if(this.filters_detalle.numero_doc) {
        payload.numero_doc = this.filters_detalle.numero_doc;
      }
      if(this.filters_detalle.del) {
        payload.del = this.filters_detalle.del;
      }
      if(this.filters_detalle.al) {
        payload.al = this.filters_detalle.al;
      }
      if (rango == null){
        if(this.filters_detalle.al) {
          delete payload.rango
          delete payload.fecha
        }
        if(this.filters_detalle.del) {
          delete payload.rango
          delete payload.fecha
        }
      }
      if (rango != null){
        delete payload.del
        delete payload.al
      }
      if (this.sending_request) return false
      this.sending_request = true

      this.sendRequest_with_url(this.url, payload).then((data) => {
        if (data.headers['content-type'] == 'application/pdf'){
          this.sending_request = false
          var blob = new Blob([data.data], { type: 'application/pdf;charset=utf-8' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report_sell.pdf';                      
          window.open(link, '_blank');
          return false;
        }
        if (payload.printer==='excel') {
          this.to_excel(data)
          return false;
        }
        this.sending_request = false
      }).catch((error) => {
        console.log(error)
        this.sending_request = false
        this.handlerError(error)
      });
    },
    to_excel(data) {
      let datetimenow = new Date();
      let datenow = datetimenow.getDate() + '-'+datetimenow.getMonth() + '-' + datetimenow.getFullYear();
      let timenow = datetimenow.getHours() + datetimenow.getMinutes()  + datetimenow.getSeconds();
      let nameexcel ='Partidas '+datenow+' '+timenow;
 
      var uri = 'data:application/vnd.ms-excel;base64,';
      var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'; 
      var base64 = function(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      };
      var format = function(s, c) {
        return s.replace(/{(\w+)}/g, function(m, p) {
          return c[p];
        })
      };

      var htmls = data.data;

      var ctx = {
        worksheet : 'Worksheet',
        table : htmls
      }

      var link = document.createElement("a");
      link.pars
      link.download = nameexcel+".xls";
      link.href = uri + base64(format(template, ctx));
      link.click();
      this.sending_request = false
    },
    fix_partida_ventas (){
      this.filters_detalle.fix_partida_ventas = 'True'
      this.list()
    },
    list() {
      let payload = {
        status: 'True',
      };
      const self = this;
      if (this.sending_request) return false
        this.sending_request = true
        payload.company = this.$store.state.company_obj.id
        if (Object.keys(this.filters_detalle).length > 0){
          Object.assign(payload, this.filters_detalle)
        }
        if (!payload.tipo_partida || payload.tipo_partida === undefined){
          delete payload.tipo_partida
        }
        if (!payload.numero_partida || payload.numero_partida === undefined){
          delete payload.numero_partida
        }
        

        this.sendRequest_with_url(this.url, payload).then((data) => {
          if (self.filters_detalle.fix_partida_ventas) {
            delete self.filters_detalle.fix_partida_ventas;
            this.sending_request = false
            return false;
          }
          self.list_data = {
            mdData: data.data
          }
          this.save_storage(this.name_storage_filter_detalle, this.filters_detalle,)
          this.sending_request = false
        }).catch((error) => {
          this.sending_request = false
          this.handlerError(error)
        });
    },
    delete_partida(item, index){
      if (item.id){
        const self = this
          Swal.fire({
            title: "¿Esta seguro?",
            text: "El registro será borrado y no se podrá recuperar.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
            cancelButtonText: "No"
          }).then((result) => {
            if (result.isConfirmed) {
              const payload = item
              payload.change_status = "True"
              self.putRequest(payload).then(function (data){
                Swal.fire(
                  'Borrado!',
                  'El registro ha sido borrado.',
                  'success'
                )
                self.sending = false
                self.$set(self.list_data.mdData, index, data.data);
              }).catch(function (error) {
                self.sending = false
                if (typeof (error.response.data) === 'object') {
                  const result = error.response.data
                  Object.entries(result).forEach(
                    ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
                  )
                  return false
                }
                self.$swal.fire('Error!', error.response.data, 'error')
              })
            }else {
              Swal.fire(
                'Cancelado',
                'El registro esta seguro.',
                'error'
              )

            }
        });
      }
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url,{params:  payload});
      // const result = await this.$http.get(this.url, payload);
      return result;
    },
    async sendRequest_with_url(url, payload) {
      payload.company = this.$store.state.company_obj.id
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async deleteRequestDocument(payload) {
      const result = await this.$http.delete(`${this.url}${payload.id}/`, payload)
      return result
    },

    handlerError: function (error) {
      if (error.response){
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        this.$swal.fire('Error!', error.response.data, 'error')
      }
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
    to_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('DD/MM/YYYY')
    },
    format_number: function (value) {
      if (!value) return ''
        return isNaN(value) ? 0 : parseFloat(value).toFixed(2)
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    status(value) {
      if (value) return 'Si'
      return 'No'
    },
  },
};

</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
  .avatar img {
    max-width: 30px;
  }
</style>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .md-menu-content {
    z-index: 11;
  }
  .null{
    text-decoration: line-through;
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .margin{
    margin-bottom: 50px;
  }

  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .error-text {
    color: #958c8c;
  }
  .null{
    text-decoration: line-through;
  }
  .md-dialog{
    z-index: 9;
  }
  @media(max-width: 820px){
    .md-dialo > .md-dialog-container{
      overflow-y: auto !important;
      height: 300px !important;
    }
  }
</style>
